import React, { Component } from 'react';

class RoomCard extends Component {
	render() {
			return (
	            <div className="room-card">
				<div className="room-title-box">
					<h1>{this.props.room.roomLabel.value}</h1>
					<div title="View floor plan" className="floor-plan-icon-contain" onClick={this.buttonCall}>
						<a href={this.props.room.floorPlanURI.value} rel="noopener noreferrer" target="_blank"><img alt="Building" className="floor-plan-icon" src="floor-plan-icon.png"></img></a>
					</div>
					{this.props.room.isSuite.value==="Yes" ? (<div className="suite" title="Room is part of a suite."><h2>S</h2></div>) : null}
				</div>
	                <div className="room-card-grid">
	                    <label>Type</label>
	                    <label>Floor</label>
	                    <label>Number</label>
	                    <label>Sqft</label>
	                    <p>{this.props.room.numOccupants.value}</p>
	                    <p>{this.props.room.floorLabel.value}</p>
	                    <p>{this.props.room.roomNumber.value}</p>
	                    <p>{this.props.room.sqft.value}</p>
	                </div>
	            </div>
			)
	}
}



export default RoomCard;