import React, { Component } from 'react';

class BuildingInfo extends Component {
    constructor(props){
        super(props);
        this.state={
        }
    }
    /**TODO: Associate proper dorm photo and floor plans with each building**/
    render() {
        return(
            <div className="building-info">
                <div className="image-wrap" onClick={this.props.onSelectBuildingImage}>
                    <img src={this.props.building.imageURL.value} alt="Selected Building"></img>
                </div>
                <div className="info-wrap">
                    <div className="building-title-div">
                        <h1>{this.props.building.buildingLabel.value}</h1>
                        <div>{this.props.building.isGPH.value==="Yes" ? <h2 title="Building is part of Greek or program housing">GPH</h2> : null}</div>
                    </div>
                    <h2>{this.props.building.buildingLocationLabel.value}</h2>
                    <div className="building-attributes">
                        <div>
                            <label>Class Year</label>
                            <p>{this.props.building.buildingClassYearLabel.value}</p>
                        </div>
                        <div>
                            <label>Laundry</label>     
                            <p>Floor {this.props.building.laundryFloorLabels.value}</p>
                        </div>
                        <div>
                            <label>Capacity</label>     
                            <p>{this.props.building.buildingCapacity.value}</p>
                        </div>
                        <div className="address-link">
                            <label>Address</label>      
                            <a style={{color:'#D32F2F', textDecoration:"underline"}} href={"https://www.google.com/maps/place/" + encodeURI(this.props.building.buildingAddress.value)}  rel="noopener noreferrer" target="_blank"><p>{this.props.building.buildingAddress.value}</p></a>
                        </div>     
                    </div>        
                </div>
            </div>
        )
    }

}



export default BuildingInfo;
