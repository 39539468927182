import React, { Component } from 'react';
import './App.css';
import FilteredList from './FilteredList'
import ReactGA from 'react-ga';
import roomData from './data/json/RoomData.json'
import buildingData from './data/json/BuildingData.json'

ReactGA.initialize('UA-158735389-1');
ReactGA.pageview('/homepage');
class App extends Component {
    constructor(props){
        super(props);
        this.state={
            roomData: roomData.results.bindings,
			buildingData: buildingData.results.bindings,
			isLoading: true,
			underMaintenace:false,
			isFirstTime: !(localStorage.getItem("first_time")),
		}
    }

	componentDidMount() {
		document.title = "Brown Bear Dens";
		if (!this.state.underMaintenace){
			this.checkFirstTime();
			this.loading();
		}
	}
	
	loading = () => {
		setTimeout( () => {
			this.setState({
				isLoading: false,
			})
			}, 1500)
	}

	
	checkFirstTime = () => {
		if (this.state.isFirstTime){
			localStorage.setItem("first_time", 1);
		}
	}

	render(){
		return(
			<div>
				{!this.state.underMaintenace ? (<FilteredList roomData={this.state.roomData} buildingData={this.state.buildingData} isLoading={this.state.isLoading} firstTime={this.state.isFirstTime}></FilteredList>) :
				(<div className="maintenace-box"><div><div class="maintenace-logo"><img src="paw.png" alt="Bear Paw"></img><h1>Bear Dens</h1></div><h2>UNDER MAINTENANCE</h2></div></div>)}
			</div>
		)
	}
}

export default App;
