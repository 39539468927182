import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import BuildingCard from './BuildingCard';
import RoomCard from './RoomCard';
import BuildingInfo from './BuildingInfo';
import Loader from 'react-loader-spinner'
import {BrowserView,MobileView,isBrowser,isMobile} from "react-device-detect";

const formStyle = isBrowser ? {
    marginRight:'3rem',
    width:'125px',
} : {marginTop:'.5rem',
    marginBottom:'1rem',
    marginRight:'4rem',
    width:'200px'
}
const sortStyle = {width:'110px', margin:'1rem',}

class FilteredList extends Component {
    constructor(props){
        super(props);
        this.state={
            numOccupants:'All',
            hasFloor:'All',
			hasLocation: 'All',
			forYear: 'All',
			isSuite: 'All',
			sort: 'Name',
            selectedBuilding:null,
            showBuildingImage:false,
            showFirstTimeMessage: true,
            mobileShowBuilding:false,
            mobileShowFilter:false,
        }
    }
    render() {
        return(
            <div className="app">
                {this.props.isLoading ? <div className="loader-contain">
                    <div>
                        <img src="paw.png" alt="logo"></img>
                        <div className="loader-logo">
                            <h1>Bear Dens</h1>
                            <h2>BrownU</h2>
                        </div>
                    </div>
                    <Loader type="Oval" color="red" height={100} width={100} timeout={3000}/>
                </div> : null}
                <div className="overlay-container" style={{display: (this.props.firstTime === true && !this.props.isLoading && this.state.showFirstTimeMessage)  ? 'flex' : 'none'}}>
                    <div className="first-time-message">
                        <div className="intro-message-wrap"><img src="paw.png" alt="logo"></img><h1>Welcome to Bear Dens!</h1></div>
                        <div>
                            <p>Bear Dens is a student project to help you prepare for the housing lottery.</p>
                            <p className="bold">It is possible that some information may not be fully accurate.</p>
                            <p> We encourage you to double check floor plans and visit the rooms you are interested in prior to the housing lottery.</p>
                        </div>
                        <button onClick={this.hideFirstTimeMessage}>Understood</button>
                    </div>
                </div>
                <BrowserView>
                    <div className="overlay-container" style={{display: (this.state.showBuildingImage) ? 'flex' : 'none'}} onClick={this.onHideOverlay}>
                        <img src={this.state.selectedBuilding != null ? this.state.selectedBuilding.imageURL.value : null} alt='building'/>
                    </div>
                    <div className="top">
                        <div className="top-banner">
                            <div className="logo">
                                <img src="paw.png" alt="logo" />
                                <h2>Bear Dens</h2>
                                <p className="disclaimer">Some rooms may not be available during the housing lottery.</p>
                            </div>
                            <div className="nav-box">
                                <div className="nav"><a href="about.html">About</a></div>
                                <div className="nav"><a href="contact.html">Contact</a></div>
                            </div>
                        </div>
                        <div className="control-contain">
                            <div className="control">
                                <FormControl style={formStyle}>
                                    <InputLabel>Class Year</InputLabel>
                                    <Select value={this.state.forYear} onChange={this.onChangeForYear}>
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <MenuItem value={'Sophomores'}>Sophomore</MenuItem>
                                        <MenuItem value={'Juniors/Seniors'}>Junior/Senior</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={formStyle}>
                                    <InputLabel>Room Capacity</InputLabel>
                                    <Select value={this.state.numOccupants} onChange={this.onChangeNumOccupants}>
                                        <MenuItem value={'All'} >All</MenuItem>
                                        <MenuItem value={'Single'}>Single</MenuItem>
                                        <MenuItem value={'Double'}>Double</MenuItem>
                                        <MenuItem value={'Triple'}>Triple</MenuItem>
                                        <MenuItem value={'Quad'}>Quad</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={formStyle}>
                                    <InputLabel>Part of Suite</InputLabel>
                                    <Select value={this.state.isSuite} onChange={this.onChangeIsSuite}>
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <MenuItem value={'Yes'}>Yes</MenuItem>
                                        <MenuItem value={'No'}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={formStyle}>
                                    <InputLabel>Location</InputLabel>
                                    <Select value={this.state.hasLocation} onChange={this.onChangeHasLocation}>
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <MenuItem value={'Wriston Quad'}>Wriston Quad</MenuItem>
                                        <MenuItem value={'Main Green'}>Main Green</MenuItem>
                                        <MenuItem value={'Graduate Center'}>Grad Center</MenuItem>
                                        <MenuItem value={'Gregorian Quad'}>Gregorian Quad</MenuItem>
                                        <MenuItem value={'Pembroke College'}>Pembroke</MenuItem>
                                        <MenuItem value={'Ruth J. Simmons Quad'}>Ruth J. Simmons</MenuItem>
                                        <MenuItem value={'Thayer Street'}>Thayer Street</MenuItem>
                                        <MenuItem value={'East'}>East Campus</MenuItem>
                                        <MenuItem value={'Machado'}>Machado</MenuItem> 
                                    </Select>
                                </FormControl>
                                <FormControl style={formStyle}>
                                    <InputLabel>Floor Level</InputLabel>
                                    <Select value={this.state.hasFloor} onChange={this.onChangeHasFloor}>
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <MenuItem value={'1'}>1</MenuItem>
                                        <MenuItem value={'2'}>2</MenuItem>
                                        <MenuItem value={'3'}>3</MenuItem>
                                        <MenuItem value={'4'}>4</MenuItem>
                                        <MenuItem value={'5'}>5</MenuItem>
                                        <MenuItem value={'6'}>6</MenuItem>
                                        <MenuItem value={'7'}>7</MenuItem>
                                        <MenuItem value={'8'}>8</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={formStyle}>
                                    <InputLabel>Sort Rooms</InputLabel>
                                    <Select value={this.state.sort} onChange={this.onChangeSort}>
                                        <MenuItem value={'Name'}>Name</MenuItem>
                                        <MenuItem value={'Square Feet'}>Square Feet</MenuItem>
                                    </Select>
                                </FormControl>
                                <div className="reset" onClick={this.onReset}>Reset</div>    
                            </div>
                        </div>
                    </div>
                    <div className="main-contain">
                        <div id="buildings" className="data-contain">
                            {this.loadData()[0]}
                        </div>
                        <div className="data-contain">
                            {(this.state.selectedBuilding==null) ? <h2 className='none-selected'>Select a building on the left to view its information and rooms.</h2>
                            : <BuildingInfo onSelectBuildingImage={this.onSelectBuildingImage} building={this.state.selectedBuilding}></BuildingInfo> }
                        </div>
                        <div id="roomDiv" className="data-contain">
                            {this.loadData()[1]}
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    {this.state.mobileShowFilter ? (<div className="mobile--filter-box">
                        <div className="mobile--filter-title-box" >
                            <h1>Filter</h1>
                            <button onClick={this.onHideFilter}><img src="x.png" alt="exit"></img></button>
                        </div>
                        <FormControl style={formStyle}>
                            <InputLabel>Class Year</InputLabel>
                            <Select value={this.state.forYear} onChange={this.onChangeForYear}>
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={'Sophomores'}>Sophomore</MenuItem>
                                <MenuItem value={'Juniors/Seniors'}>Junior/Senior</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={formStyle}>
                            <InputLabel>Room Capacity</InputLabel>
                            <Select value={this.state.numOccupants} onChange={this.onChangeNumOccupants}>
                                <MenuItem value={'All'} >All</MenuItem>
                                <MenuItem value={'Single'}>Single</MenuItem>
                                <MenuItem value={'Double'}>Double</MenuItem>
                                <MenuItem value={'Triple'}>Triple</MenuItem>
                                <MenuItem value={'Quad'}>Quad</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={formStyle}>
                            <InputLabel>Part of Suite</InputLabel>
                            <Select value={this.state.isSuite} onChange={this.onChangeIsSuite}>
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                <MenuItem value={'No'}>No</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={formStyle}>
                            <InputLabel>Location</InputLabel>
                            <Select value={this.state.hasLocation} onChange={this.onChangeHasLocation}>
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={'Wriston Quad'}>Wriston Quad</MenuItem>
                                <MenuItem value={'Main Green'}>Main Green</MenuItem>
                                <MenuItem value={'Graduate Center'}>Grad Center</MenuItem>
                                <MenuItem value={'Gregorian Quad'}>Gregorian Quad</MenuItem>
                                <MenuItem value={'Pembroke College'}>Pembroke</MenuItem>
                                <MenuItem value={'Ruth J. Simmons Quad'}>Ruth J. Simmons</MenuItem>
                                <MenuItem value={'Thayer Street'}>Thayer Street</MenuItem>
                                <MenuItem value={'East'}>East Campus</MenuItem>
                                <MenuItem value={'Machado'}>Machado</MenuItem> 
                            </Select>
                        </FormControl>
                        <FormControl style={formStyle}>
                            <InputLabel>Floor Level</InputLabel>
                            <Select value={this.state.hasFloor} onChange={this.onChangeHasFloor}>
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={'1'}>1</MenuItem>
                                <MenuItem value={'2'}>2</MenuItem>
                                <MenuItem value={'3'}>3</MenuItem>
                                <MenuItem value={'4'}>4</MenuItem>
                                <MenuItem value={'5'}>5</MenuItem>
                                <MenuItem value={'6'}>6</MenuItem>
                                <MenuItem value={'7'}>7</MenuItem>
                                <MenuItem value={'8'}>8</MenuItem>
                            </Select>
                        </FormControl>
                        <div className="mobile--apply-filter" onClick={this.onHideFilter}>Apply Filters</div>
                        <div className="mobile--reset-filter" onClick={this.onReset}>Reset Filters</div>
                    </div>) : null}
                    <div className="mobile--page-title-box">
                        <div>
                            {this.state.mobileShowBuilding ? (<button className="button-wrap"onClick={this.onReturn}><img className="back-img" src="back.png" alt="back"></img></button>) : <img className="back-img" src="paw.png" alt="logo"></img>}
                            {!this.state.mobileShowBuilding ? (<h1>Bear Dens</h1>) : (<h1>Building Info</h1>)}
                        </div>
                        <button onClick={this.onShowFilter}><img alt="filter" className="filter-img" src="filter.png"></img></button>
                    </div>
                    {!this.state.mobileShowBuilding ?     
                    <div>   
                        <div className="data-contain" >
                            {this.loadData()[0]}
                        </div> 
                    </div> :
                    <div>
                        <BuildingInfo onSelectBuildingImage={this.onSelectBuildingImage} building={this.state.selectedBuilding}></BuildingInfo>
                        <div className="mobile--room-title-box">
                            <h2>Rooms</h2>
                            <FormControl style={sortStyle}>
                                <InputLabel>Sort rooms by</InputLabel>
                                <Select value={this.state.sort} onChange={this.onChangeSort}>
                                    <MenuItem value={'Name'}>Name</MenuItem>
                                    <MenuItem value={'Square Feet'}>Square Feet</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="data-contain">
                            {this.loadData()[1]}
                        </div>
                    </div>}
                    <div className="mobile--footer">
                        <div className="mobile--footer-logo">
                            <img src="paw.png" alt="logo"></img>
                            <h1>Bear Dens</h1>
                        </div>
                        <div className="nav-box">
                            <div className="nav"><a href="about.html">About</a></div>
                            <div className="nav"><a href="contact.html">Contact</a></div>
                        </div>
                    </div>
                </MobileView>
            </div>)
    }

    /**reset button code**/
    onReset = () => {
        this.setState({
		numOccupants:'All',
        hasFloor:'All',
		hasLocation: 'All',
		forYear: 'All',
		isSuite: 'All',
		sort: 'Name',
        selectedBuilding:null,
        mobileShowBuilding:false,
        mobileShowFilter:false,
    })
    };
    
    /**upon changing filters, update state**/
    onChangeNumOccupants = event => {
        this.setState({numOccupants:event.target.value});
    }
	
    onChangeHasFloor = event => {
        this.setState({hasFloor:event.target.value});
    }
	
    onChangeHasLocation = event => {
        this.setState({hasLocation:event.target.value, selectedBuilding: (isBrowser) ? null : this.state.selectedBuilding});
    }
	
    onChangeForYear = event => {
        this.setState({forYear:event.target.value}) ;
    }
	
    onChangeIsSuite = event => {
        this.setState({isSuite:event.target.value}) ;
    }
	
    onChangeSort = event => {
        this.setState({sort:event.target.value}) ;
    }
	
	onSelectBuilding = building => {
		if(building !== this.state.selectedBuilding){
            this.setState({selectedBuilding:building});
            if(isBrowser){
                document.getElementById("roomDiv").scrollTop = 0;
            }
        }
        if(isMobile){
            this.setState({mobileShowBuilding:true});
            window.scrollTo(0, 0);
        }
    }
    /**handles return on mobile */
    onReturn = () => {
        this.setState({mobileShowBuilding:false});
        window.scrollTo(0, 0);
    }
    
    /**while iterating through rooms, check if match */
    matchesNumOccupants = roomData => {
      if (roomData.numOccupants.value === this.state.numOccupants || this.state.numOccupants === "All") {
        return true;
      } 
      return false;
    };
	
    matchesFloor = roomData => {
      if (roomData.floorLabel.value === this.state.hasFloor || this.state.hasFloor === "All") {
        return true;
      } 
      return false;
    };
	
    matchesLocation = roomData => {
      if (roomData.buildingLocationLabel.value === this.state.hasLocation || this.state.hasLocation === "All") {
        return true;
      } 
      return false;
    };
	
    matchesYear = roomData => {
      if (roomData.buildingClassYearLabel.value === this.state.forYear || this.state.forYear === "All" || roomData.buildingClassYearLabel.value === "Any") {
        return true;
      } 
      return false;
    };
	
    matchesSuite = roomData => {
      if (roomData.isSuite.value === this.state.isSuite || this.state.isSuite === "All") {
        return true;
      } 
      return false;
    };
	
    matchesSelectedBuilding = roomData => {
        if(this.state.selectedBuilding === null){return}
        if (roomData.buildingLabel.value === this.state.selectedBuilding.buildingLabel.value){
            return true;
        }
        return false;
    }
    
    applyFilters = roomData => {
        if(this.matchesNumOccupants(roomData) && this.matchesFloor(roomData) && this.matchesLocation(roomData) && this.matchesYear(roomData) && this.matchesSuite(roomData)){
            return true;
        }
        return false;
    }
	
    sortRooms = () => {
        if (this.state.sort === "Square Feet") {
  		  return this.props.roomData.sort((a,b) => b.sqft.value-a.sqft.value);
        } 
        if (this.state.sort === "Name") {
  		    return this.props.roomData.sort((a,b) => a.roomLabel.value.localeCompare(b.roomLabel.value));
        } 
    }
    /**toggles for floor plans*/
    onShowOverlay = (room) => {
        this.setState({
            currFloorPlan:room,
        })
    }
    onHideOverlay = () => {
        this.setState({
            showBuildingImage:false,
        })
    }

    onSelectBuildingImage = () =>{
        
        this.setState({
            showBuildingImage:true,
        })
    }

    onShowFilter = () => {
        this.setState({
            mobileShowFilter:true,
        })
    }
    onHideFilter = () => {
        this.setState({
            mobileShowFilter:false,
        })
        window.scrollTo(0, 0);
    }
    hideFirstTimeMessage = () => {
        this.setState({
            showFirstTimeMessage: false,
        })
    }
    /**load the building list to be render, room lsit to be rendered, count rooms associated with given building **/
    loadData = () => { 
        let roomList=[];
        let buildingList;
        let buildingDict = {};
        for (var i=0; i<this.props.buildingData.length;i++){
            let curr = this.props.buildingData[i];
            buildingDict[curr.buildingLabel.value] = 0;
        } 
        let sortedList = this.sortRooms() ;
        for (let j=0; j<sortedList.length; j++) {
            let curr = sortedList[j];
            if(this.applyFilters(curr)) {
                buildingDict[curr.buildingLabel.value] += 1; 
                if (this.matchesSelectedBuilding(curr)){
                    roomList.push(<RoomCard room={curr}></RoomCard>)
                }
            }
        }

        if (roomList.length === 0 && this.state.selectedBuilding!=null){
            roomList=[(<h2 className="none-selected">No rooms in {this.state.selectedBuilding.buildingLabel.value} match your filters.</h2>)]
        }

        let inactiveBuildings = [];
        let activeBuildings = [];
        for (let k=0; k<this.props.buildingData.length; k++){
            let curr = this.props.buildingData[k];
            if (buildingDict[curr.buildingLabel.value] > 0){
                activeBuildings.push(<BuildingCard selected={this.state.selectedBuilding} building={curr} onClick={this.onSelectBuilding} count={buildingDict[curr.buildingLabel.value]}></BuildingCard>)
            }
            else{
                inactiveBuildings.push(<BuildingCard selected={this.state.selectedBuilding} building={curr} onClick={this.onSelectBuilding} count={buildingDict[curr.buildingLabel.value]}></BuildingCard>)
            }
        }
        buildingList = activeBuildings.concat(inactiveBuildings);
        return [buildingList, roomList]
    }
    countSuiteSize(rooms) {
        let currSuite = null;
        let currCount = 0;
        let list = [];
        for (let i=0; i < this.props.roomData.length; i++){
            let currRoom = this.props.roomData[i];
            if (currRoom.isSuite.value === "Yes"){
                let currRoomName = currRoom.roomLabel.value.substring(0, currRoom.roomLabel.value.length-3);
                let roomOcc = currRoom.numOccupants.value;
                if (currSuite === null) {
                    currSuite = currRoomName;
                    currCount = (roomOcc === 'Single') ? 1 : (roomOcc === 'Double') ? 2 : (roomOcc === 'Triple') ? 3 : 0;
                }
                else if (currSuite === currRoomName) {
                    currCount += (roomOcc === 'Single') ? 1 : (roomOcc === 'Double') ? 2 : (roomOcc === 'Triple') ? 3 : 0;
                }
                else if (currSuite !== currRoomName){
                    list.push(currSuite + ": " + currCount);
                    currCount = (roomOcc === 'Single') ? 1 : (roomOcc === 'Double') ? 2 : (roomOcc === 'Triple') ? 3 : 0;
                    currSuite = currRoomName;
                }
            }
        }
        for (let s of list){
            console.log(s);
        }
    }

}

 


export default FilteredList;
