import React, { Component } from 'react';
import {isBrowser} from "react-device-detect";

class BuildingCard extends Component {
	render() {
			/*the code in the div tag checks if the selected building matches this card's building, if so, change background color to yellow. */
		return (
			<div className={"building-card"} onClick={this.onClick} >
				<div className="card-over" style=
				{{boxShadow: ((isBrowser && (this.props.selected != null  )&& this.props.building.buildingLabel.value === this.props.selected.buildingLabel.value) ? 'inset 0 0 8px #E0E0E0' : null),
				}} >
					<div>
						<h1>{this.props.building.buildingLabel.value}</h1>
						<h2>{this.props.building.buildingLocationLabel.value}</h2>
					</div>
					<p style={{color:(this.props.count>0 ? "#D32F2F" : "#757575")}}>
					{this.props.count} matches</p>
				</div>
			</div>
		)
	}

	/**this is a callback function that returns the associated building to FilteredList**/
	onClick = () => {
		this.props.onClick(this.props.building);
	}
}



export default BuildingCard;
